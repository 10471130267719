import * as $ from "jquery";
import jqXHR = JQuery.jqXHR;

export class BackupLog {

    public id;
    public user;

    public files;
    public regularFiles;
    public directories;
    public links;

    public createdFiles;
    public createdRegularFiles;
    public createdDirectories;
    public createdLinks;

    public deletedFiles;
    public deletedRegularFiles;
    public deletedDirectories;
    public deletedLinks;

    public totalFileSize;
    public totalTransferredFileSize;

    constructor(id: number, user: number) {
        this.id = id;
        this.user = user;
    }

    public fetch(): jqXHR
    {
        return $.get({
            url: '/api/backup/' + this.user + '/log/' + this.id,
            dataType: 'json'
        }).done((response) => {
            this.build(response.body);
        }).fail((response, status, error) => {
            return new Error( 'Laden des Backup Log ' + this.id + ' ist fehlgeschlagen. [ ' + error + ' ]' );
        });
    }

    private build(data): void
    {
        this.files = data.files;
        this.regularFiles = data.regular_files;
        this.directories = data.directories;
        this.links = data.links;

        this.createdFiles = data.created_files;
        this.createdRegularFiles = data.created_regular_files;
        this.createdDirectories = data.created_directories;
        this.createdLinks = data.created_links;

        this.deletedFiles = data.deleted_files;
        this.deletedRegularFiles = data.deleted_regular_files;
        this.deletedDirectories = data.deleted_directories;
        this.deletedLinks = data.deleted_links;

        this.totalFileSize = data.size;
        this.totalTransferredFileSize = data.transferred_size;
    }

    public getSummaryData()
    {
        return {
            datasets: [{
                data: [
                    this.createdRegularFiles,
                    this.createdDirectories,
                    this.createdLinks,
                    this.deletedFiles
                ],
                backgroundColor: [
                    '#2baab1',
                    '#36cad2',
                    '#3be5ec',
                    '#195960'
                ]
            }],
            labels: [
                'Erstellte Dateien',
                'Erstellte Verzeichnisse',
                'Erstellte Links',
                'Gelöschte Dateien'
            ]
        }
    }

}
