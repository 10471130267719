import * as $ from "jquery";

class ServerStats {

    private id: number;
    private timestamp: number;
    private total: number;
    private used: number;

    private data;

    public fetch(): Promise<void>
    {
        return new Promise((resolve, reject) => {
            $.get({
                url: '/api/server/stats/latest',
                dataType: 'json'
            }).done((response) => {
                this.build(response.body);
                resolve();
            }).fail((response, status, error) => {
                return reject(Error('Laden der Zusammenfassung fehlgeschlagen. [ ' + error + ' ]'));
            });
        });
    }

    public fetchWeek(): Promise<any>
    {
        return new Promise((resolve, reject) => {
            $.get({
                url: '/api/server/stats',
                dataType: 'json'
            }).done((response) => {
                this.data = response.body;
                resolve();
            }).fail((response, status, error) => {
                return reject(Error('Laden der Zusammenfassung fehlgeschlagen. [ ' + error + ' ]'));
            });
        });
    }

    private build(data): void
    {
        this.id = data.id;
        this.total = data.total;
        this.used = data.used;
        this.timestamp = data.timestamp;
    }

    public getLabelsArray(): Array<string>
    {
        return [
            'Frei',
            'Benutzt'
        ];
    }

    public getDataArray(): Array<number>
    {
        return [
            (this.total - this.used),
            this.used
        ]
    }

    public getWeekLabelsArray(): Array<string>
    {
        let labels = [];
        for (let i in this.data) {
            if(this.data.hasOwnProperty(i)) {
                labels.push(new Date(this.data[i].timestamp * 1000).toLocaleString());
            }
        }
        return labels;
    }

    public getWeekDataArray(): Array<number>
    {
        let data = [];
        for (let i in this.data) {
            if(this.data.hasOwnProperty(i)) {
                data.push(this.data[i].used);
            }
        }
        return data;
    }

}

export default ServerStats;
