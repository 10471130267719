import * as $ from "jquery";
import {JSONRequest} from "./JSONRequest";


export default class WebUser {

    private id: number;
    private name: string;
    private email: string;
    private phone: string;

    private password: string;
    private passwordRepeat: string;

    private message = (title: string, message: string, className: string) => {
        return  '<div id="message_container" class="' + className + ' notification">' +
                    '<button class="delete"></button>' +
                    '<strong>' + title + '</strong> ' + message +
                '</div>';
    };

    public static fromPasswordForm(form: JQuery): WebUser
    {
        let self = new WebUser();
        self.id = form.data('id');
        self.name = form.data('name');
        self.password = form.find('#password').val().toString();
        self.passwordRepeat = form.find('#password_repeat').val().toString();
        return self;
    }

    public static fromProfileForm(form: JQuery): WebUser
    {
        let self = new WebUser();
        self.id = form.data('id');
        self.name = form.data('name');
        self.phone = form.find('#phone').val().toString();
        self.email = form.find('#email').val().toString();
        return self;
    }

    public update(messageContainer: JQuery, form: JQuery)
    {
        JSONRequest.put(
            '/api/user/' + this.name + '/profile',
            this.toJSON(),
            messageContainer,
            form
        ).then((response) => {
            messageContainer.replaceWith(this.message(response['title'], response['message'], 'is-primary'));
        }).catch((exception: Error) => {
            messageContainer.replaceWith(this.message('', exception.message, 'is-danger'));
        });
    }

    public patchPassword(messageContainer: JQuery, form: JQuery)
    {
        JSONRequest.patch(
            '/api/user/' + this.name + '/password',
            this.toJSON(),
            messageContainer,
            form
        ).then((response) => {
            messageContainer.replaceWith(this.message(response['title'], response['message'], 'is-primary'));
        }).catch((exception: Error) => {
            messageContainer.replaceWith(this.message('', exception.message, 'is-danger'));
        });
    }

    private toJSON()
    {
        return {
            id: this.id,
            name: this.name,
            email: this.email,
            phone: this.phone,
            password: this.password,
            password_repeat: this.passwordRepeat
        }
    }

}
