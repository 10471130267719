import {JSONRequest} from "./JSONRequest";


export default class BackupNotification {

    private id: number;
    private token: string;
    private action: number;
    private reason: string;
    private notification: number;

    private message = (title: string, message: string, className: string) => {
        return  '<div id="message_container" class="' + className + ' notification">' +
            '<button class="delete"></button>' +
            '<strong>' + title + '</strong> ' + message +
            '</div>';
    };

    public static fromForm(form: JQuery)
    {
        let self = new BackupNotification();
        self.token = form.data('token');
        self.reason = form.find('#solution').val().toString();
        self.notification = Number(form.find('#notification').val());
        return self;
    }

    public update(messageContainer: JQuery, form: JQuery)
    {
        JSONRequest.put(
            '/api/token/' + this.token,
            this.toJSON(),
            messageContainer,
            form
        ).then((response) => {
            messageContainer.replaceWith(this.message(response['title'], response['message'], 'is-primary'));
        }).catch((exception: Error) => {
            messageContainer.replaceWith(this.message('', exception.message, 'is-danger'));
        });
    }

    private toJSON()
    {
        return {
            id: this.id,
            token: this.token,
            action: this.action,
            reason: this.reason,
            notification: this.notification
        };
    }

}
