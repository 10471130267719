import * as $ from "jquery";


export class BackupSummary {

    public user;

    public data;

    constructor(user: number) {
        this.user = user;
    }

    public fetch()
    {
        return new Promise((resolve, reject) => {
            $.get({
                url: '/api/backup/' + this.user + '/summary',
                dataType: 'json'
            }).done((response) => {
                this.data = response.body;
                resolve(response.body);
            }).fail((response, status, error) => {
                return reject(Error('Laden der Zusammenfassung fehlgeschlagen. [ ' + error + ' ]'));
            });
        });
    }

    public getStatusLabelArray(): Array<string>
    {
        return [
            'Erfolgreich',
            'Erfolgreich mit Fehlern',
            'Nicht durchgeführt',
            'Fehlgeschlagen',
            'Benutzer existierte noch nicht'
        ]
    }

    public getStatusArray(): Array<number>
    {
        let status = [0,0,0,0,0];
        for(let i in this.data) {
            if(this.data.hasOwnProperty(i)) {
                let log = this.data[i];
                status[log.status_code - 1]++;
            }
        }
        return status;
    }

    public getSizeArray()
    {
        let sizes = [];
        for (let i in this.data) {
            if(this.data.hasOwnProperty(i)) {
                let log = this.data[i];
                sizes.push(Math.round(log.size / 1073741824));
            }
        }
        return sizes;
    }

    public getLabelsArray()
    {
        let labels = [];
        for (let i in this.data) {
            if(this.data.hasOwnProperty(i)) {
                let log = this.data[i];
                labels.push(log.date);
            }
        }
        return labels;
    }

}
