import * as $ from "jquery";

export class CompleteSummary {

    private users: object = {};

    public fetch(): Promise<void>
    {
        return new Promise((resolve, reject) => {
            $.get({
                url: '/api/backup/summary',
                dataType: 'json'
            }).done((response) => {
                Object.keys(response.body).forEach(user => {
                    this.users[user] = response.body[user];
                });
                resolve();
            }).fail((response, status, error) => {
                return reject(Error('Laden fehlgeschlagen [ ' + error + ' ]'));
            });
        });
    }

    public getStatusLabels(): Array<string>
    {
        return [
            'Erfolgreich',
            'Erfolgreich mit Fehlern',
            'Fehlgeschlagen',
            'Existiert nicht',
        ];
    }

    public getStatusValues(): Array<number>
    {
        let values = [0,0,0,0];
        Object.keys(this.users).forEach(user => {
            let index = this.users[user].status_code;
            // skip status code 3 (not done) because its not relevant in this chart and will never occur
            index = index > 3 ? index - 2 : index - 1;
            values[index]++;
        });
        return values;
    }

    public getTimeOverviewLabels(): Array<string>
    {
        return [
            'Heute',
            '1 - 3 Tage',
            '4 - 5 Tage',
            'Älter',
            'Existiert nicht'
        ]
    }

    public getTimeOverviewValues(): Array<number>
    {
        let values = [0,0,0,0,0];
        let today = new Date();
        today.setHours(0,0,0,0);
        Object.keys(this.users).forEach(user => {

            if(this.users[user].status_code === 5) {
                values[4]++;
                return;
            }

            let parts = this.users[user].date.split('.');
            let days = Math.round(Math.abs(today.getTime() - new Date(parts[2], parts[1] - 1, parts[0]).getTime()) / 86400000);
            switch(days) {
                case 0: values[0]++; break;
                case 1:
                case 2:
                case 3: values[1]++; break;
                case 4:
                case 5: values[2]++; break;
                default: values[3]++; break;
            }
        });
        return values;
    }

}
