import {JSONRequest} from "./JSONRequest";


export default class BackupRestore {

    private backupId: number;
    private userId: number;

    private message = (title: string, message: string, className: string) => {
        return `<div id="message_container" class="${className} notification">
                    <button class="delete"></button>
                    <strong>${title}</strong>: ${message}.
                </div>`;
    };

    private error = (message: string, className: string) => {
        return `<div id="message_container" class="${className} notification">
                    <button class="delete"></button>
                    ${message}
                </div>`;
    };

    public static fromLogView(button: JQuery)
    {
        let self = new BackupRestore();
        self.backupId = button.data('backup');
        self.userId = button.data('user');
        return self;
    }

    public create(messageContainer: JQuery)
    {
        JSONRequest.post(
            '/api/backup/restore/' + this.backupId, this.toJson()
        ).then(response => {
            messageContainer.replaceWith(this.message(response['title'], response['message'], 'is-primary'));
        }).catch((error: Error) => {
            messageContainer.replaceWith(this.error(error.message, 'is-danger'));
        })
    }

    private toJson()
    {
        return {
            user: this.userId
        }
    }

}
